/**
 * @generated SignedSource<<7687609456435d1fba3a2d8b3c0329e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AIGenerationReferenceStatus = "pending" | "completed" | "failed" | "%future added value";
export type AssetFileType = "image" | "video" | "document" | "subtitle" | "unknown" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AIGenerationReferenceListFragment$data = {
  readonly references: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly status: AIGenerationReferenceStatus;
        readonly url: string | null;
        readonly summary: string | null;
        readonly embeddingSource: {
          readonly asset: {
            readonly id: string;
            readonly name: string;
            readonly fileType: AssetFileType;
            readonly sizeBytes: number;
          } | null;
          readonly content: {
            readonly id: string;
            readonly name: string | null;
            readonly type: ContentType;
            readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnail_ContentFragment">;
          } | null;
        } | null;
        readonly uploadedAsset: {
          readonly id: string;
          readonly name: string;
          readonly sizeBytes: number;
          readonly fileType: AssetFileType;
        } | null;
      };
    }>;
  };
  readonly " $fragmentType": "AIGenerationReferenceListFragment";
};
export type AIGenerationReferenceListFragment$key = {
  readonly " $data"?: AIGenerationReferenceListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AIGenerationReferenceListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sizeBytes",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AIGenerationReferenceListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AIGenerationReferenceNodeConnection",
      "kind": "LinkedField",
      "name": "references",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AIGenerationReferenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AIGenerationReference",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "summary",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EmbeddingSource",
                  "kind": "LinkedField",
                  "name": "embeddingSource",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Asset",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ContentThumbnail_ContentFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Asset",
                  "kind": "LinkedField",
                  "name": "uploadedAsset",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AIGeneration",
  "abstractKey": null
};
})();

(node as any).hash = "6a767a6a873e074faa95c8b1f513c1ac";

export default node;
