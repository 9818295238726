import ContentThumbnail from "@/content/detail/ContentThumbnail"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import VerifyIcon from "@/core/ui/iconsax/bold/verify.svg"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoIcon, DiscoText, DiscoTooltip, DiscoSpinner } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { Collapse, useTheme } from "@material-ui/core"
import { getFaviconUrl } from "@utils/url/urlUtils"
import size from "filesize"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { AIGenerationReferenceListAssetDownloadUrlQuery } from "./__generated__/AIGenerationReferenceListAssetDownloadUrlQuery.graphql"
import { AIGenerationReferenceListFragment$key } from "./__generated__/AIGenerationReferenceListFragment.graphql"

type AIGenerationReferenceListProps = {
  referencesKey: AIGenerationReferenceListFragment$key
}

function AIGenerationReferenceList({ referencesKey }: AIGenerationReferenceListProps) {
  const classes = useStyles()
  const theme = useTheme()
  const contentDrawer = useGlobalDrawer("adminContent")
  const [isExpanded, setIsExpanded] = useState(true)

  const data = useFragment<AIGenerationReferenceListFragment$key>(
    graphql`
      fragment AIGenerationReferenceListFragment on AIGeneration {
        references {
          edges {
            node {
              id
              status
              url
              summary
              embeddingSource {
                asset {
                  id
                  name
                  fileType
                  sizeBytes
                }
                content {
                  id
                  name
                  type
                  ...ContentThumbnail_ContentFragment
                }
              }
              uploadedAsset {
                id
                name
                sizeBytes
                fileType
              }
            }
          }
        }
      }
    `,
    referencesKey
  )

  const references = Relay.connectionToArray(data?.references)

  if (references.length === 0) return null

  // Get unique reference icons for the header
  const getUniqueReferenceIcons = () => {
    const iconMap = new Map()

    // First pass: collect URLs with favicons
    references.forEach((ref) => {
      if (ref.url && getFaviconUrl(ref.url)) {
        iconMap.set("favicon", { type: "favicon", url: getFaviconUrl(ref.url) })
      }
    })

    // Second pass: collect content thumbnails by type and other types
    references.forEach((ref) => {
      if (ref.embeddingSource?.content?.type) {
        // Use content type as the key to ensure one of each type
        const contentType = ref.embeddingSource.content.type
        if (!iconMap.has(`content-${contentType}`)) {
          iconMap.set(`content-${contentType}`, {
            type: "content",
            content: ref.embeddingSource.content,
          })
        }
      } else if (ref.url && !iconMap.has("link")) {
        iconMap.set("link", { type: "icon", name: "link" })
      } else if (
        ref.embeddingSource?.asset?.fileType === "video" &&
        !iconMap.has("video")
      ) {
        iconMap.set("video", { type: "icon", name: "video-square" })
      } else if (!iconMap.has("file") && !ref.url && !ref.embeddingSource?.content) {
        iconMap.set("file", { type: "icon", name: "file" })
      }
    })

    return Array.from(iconMap.values()).slice(0, 5)
  }

  const headerIcons = getUniqueReferenceIcons()

  async function handleAssetClick(assetId: string) {
    const response = await Relay.runQuery<AIGenerationReferenceListAssetDownloadUrlQuery>(
      graphql`
        query AIGenerationReferenceListAssetDownloadUrlQuery($id: ID!) {
          node(id: $id) {
            ... on Asset {
              downloadUrl
            }
          }
        }
      `,
      {
        id: assetId,
      },
      { fetchPolicy: "network-only" }
    )
    if (response?.node?.downloadUrl) {
      window.open(response.node.downloadUrl, "_blank", "noopener,noreferrer")
      return
    }
    displayErrorToast("Unable to generate download URL. Please try again later.")
  }

  return (
    <div className={classes.container}>
      <DiscoContainerButton
        className={classes.sourcesHeader}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={classes.headerLeft}>
          <DiscoIcon
            icon={"file"}
            width={16}
            height={16}
            color={theme.palette.grey[700]}
          />
          <DiscoText variant={"body-md-700"}>{"Sources"}</DiscoText>
        </div>
        <div className={classes.headerRight}>
          <div className={classes.headerIcons}>
            {headerIcons.map((icon) => (
              <div key={icon} className={classes.headerIcon}>
                {icon.type === "favicon" ? (
                  <DiscoImage
                    src={icon.url}
                    className={classes.favicon}
                    width={16}
                    height={16}
                  />
                ) : icon.type === "content" ? (
                  <div className={classes.thumbnailContainer}>
                    <ContentThumbnail contentKey={icon.content} />
                  </div>
                ) : (
                  <DiscoIcon
                    icon={icon.name}
                    height={16}
                    width={16}
                    color={theme.palette.grey[700]}
                  />
                )}
              </div>
            ))}
          </div>
          <DiscoIcon
            icon={"chevron"}
            width={16}
            height={16}
            className={classes.expandIcon}
            rotate={isExpanded ? undefined : "-180"}
            color={theme.palette.text.primary}
          />
        </div>
      </DiscoContainerButton>
      <Collapse in={isExpanded}>
        <div className={classes.referenceList}>
          {references.map((ref) => {
            const displayName = ref.url
              ? ref.url
              : ref.embeddingSource?.content?.name ||
                ref.embeddingSource?.asset?.name ||
                ref.uploadedAsset?.name ||
                ""

            const asset = ref.embeddingSource?.asset || ref.uploadedAsset
            const fileType = asset?.fileType?.toLowerCase() || ""
            const sizeBytes = asset?.sizeBytes || 0
            const formattedSize = sizeBytes
              ? size.partial({ spacer: "", round: 1 })(sizeBytes).toUpperCase()
              : ""
            const fileExtension =
              asset?.name?.split(".").pop()?.toUpperCase() || fileType.toUpperCase()

            const fileInfo = [fileExtension, formattedSize].filter(Boolean).join(" · ")

            const handleClick = () => {
              if (ref.url) {
                window.open(ref.url, "_blank", "noopener,noreferrer")
              } else if (ref.embeddingSource?.content?.id) {
                contentDrawer.open({
                  contentId: ref.embeddingSource.content.id,
                })
              } else if (asset?.id) {
                handleAssetClick(asset.id)
              }
            }

            return (
              <div key={ref.id} className={classes.referenceItem}>
                <DiscoContainerButton
                  onClick={handleClick}
                  className={classes.referenceButton}
                >
                  <div className={classes.contentContainer}>
                    <div className={classes.contentInfo}>
                      <div className={classes.sourceTypeRow}>
                        <DiscoText
                          truncateText={1}
                          variant={"body-xs-700"}
                          className={classes.referenceName}
                        >
                          {displayName}
                        </DiscoText>
                        <div className={classes.statusContainer}>
                          <ReferenceWarning status={ref.status} />
                          <ReferenceSourceType isWebSource={Boolean(ref.url)} />
                        </div>
                      </div>
                      <div className={classes.typeContainer}>
                        {ref.url ? (
                          <div className={classes.previewContainer}>
                            {(() => {
                              const faviconUrl = getFaviconUrl(ref.url)
                              return faviconUrl ? (
                                <DiscoImage
                                  src={faviconUrl}
                                  className={classes.favicon}
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <DiscoIcon icon={"link"} height={16} width={16} />
                              )
                            })()}
                          </div>
                        ) : ref.embeddingSource?.content ? (
                          <div className={classes.thumbnailContainer}>
                            <ContentThumbnail contentKey={ref.embeddingSource.content} />
                          </div>
                        ) : (
                          asset && (
                            <div className={classes.previewContainer}>
                              <DiscoIcon
                                icon={fileType === "video" ? "video-square" : "file"}
                                height={16}
                                width={16}
                              />
                            </div>
                          )
                        )}
                        <DiscoText variant={"body-xs-600"} className={classes.typeText}>
                          {ref.embeddingSource?.content?.type || fileInfo || "URL"}
                        </DiscoText>
                      </div>
                    </div>
                  </div>
                </DiscoContainerButton>
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}

const ReferenceWarning = ({ status }: { status: string }) => {
  const theme = useTheme()
  const classes = useStyles()

  if (status === "pending") {
    return (
      <DiscoTooltip content={"Processing..."}>
        <div className={classes.warningIconContainer}>
          <DiscoSpinner
            size={"xs"}
            fullWidth={false}
            containerClassName={classes.spinnerContainer}
          />
        </div>
      </DiscoTooltip>
    )
  }

  if (status !== "failed") return null

  return (
    <DiscoTooltip content={"Failed to extract information from this reference"}>
      <div className={classes.warningIconContainer}>
        <DiscoIcon
          icon={"warning"}
          color={theme.palette.text.danger}
          width={16}
          height={16}
        />
      </div>
    </DiscoTooltip>
  )
}

const ReferenceSourceType = ({ isWebSource }: { isWebSource: boolean }) => {
  const theme = useTheme()
  return (
    <DiscoTooltip content={isWebSource ? "Web Source" : "Verified Source"}>
      {isWebSource ? (
        <DiscoIcon
          icon={"globe"}
          width={16}
          height={16}
          color={theme.palette.primary.main}
        />
      ) : (
        <VerifyIcon width={16} height={16} color={theme.palette.primary.main} />
      )}
    </DiscoTooltip>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: "100%",
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    borderRadius: theme.measure.borderRadius.medium,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  sourcesHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 1),
    cursor: "pointer",
    borderRadius: theme.measure.borderRadius.medium,
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  headerIcons: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  headerIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expandIcon: {
    transition: "transform 300ms ease-in-out",
    marginRight: theme.spacing(1),
  },
  referenceList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  referenceItem: {
    width: "100%",
    height: 64,
  },
  referenceButton: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
      backgroundColor: theme.palette.groovy.neutral[200],
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  contentContainer: {
    display: "flex",
    gap: theme.spacing(1),
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      height: 16,
      width: 16,
    },
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  contentInfo: {
    gap: theme.spacing(0.5),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: 0,
  },
  referenceName: {
    color: theme.palette.text.primary,
  },
  typeContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    height: 16,
  },
  typeText: {
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    lineHeight: 1,
  },
  previewContainer: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& img": {
      maxWidth: "16px",
      maxHeight: "16px",
      width: "auto",
      height: "auto",
    },
  },
  favicon: {
    borderRadius: "50%",
  },
  warningIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  sourceTypeRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: theme.spacing(1),
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default AIGenerationReferenceList
