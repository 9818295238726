import AIButton from "@/components/ai/AIButton"
import { ResumeWithAIFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  aiGenerationId: GlobalID
  children: string
  onSuccess?: () => void
  disabled?: boolean
  form: FormStore<ResumeWithAIFormState>
}

function ResumeWithAIButton({
  aiGenerationId,
  children,
  onSuccess,
  disabled,
  form,
  testid = "ResumeAIButton",
}: Props) {
  async function handleClick() {
    const { didSave } = await form.submit({
      id: aiGenerationId,
      acceptedMessageId: form.state.acceptedMessageId,
    })
    if (didSave) {
      onSuccess?.()
    }
  }

  const isDisabled = !form.state.acceptedMessageId || disabled

  return (
    <AIButton
      testid={testid}
      onClick={handleClick}
      disabled={isDisabled}
      shouldDisplaySpinnerWithText={form.isSubmitting}
    >
      {children}
    </AIButton>
  )
}

export default observer(ResumeWithAIButton)
