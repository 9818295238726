/**
 * @generated SignedSource<<d3b221d81a753ddf04b0b7b5558907e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AIGenerationMessageKind = "output" | "input" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AICanvasDrawerMessageFragment$data = {
  readonly id: string;
  readonly kind: AIGenerationMessageKind;
  readonly output: {
    readonly searchResults: ReadonlyArray<{
      readonly moduleTitle: string | null;
      readonly query: string;
      readonly urls: ReadonlyArray<string>;
    }> | null;
    readonly curriculumModules: ReadonlyArray<{
      readonly title: string;
      readonly content: ReadonlyArray<{
        readonly title: string;
        readonly type: ContentType;
        readonly summary: string;
      }>;
    }> | null;
  } | null;
  readonly input: {
    readonly prompt: string | null;
    readonly searchWeb: boolean | null;
  } | null;
  readonly " $fragmentType": "AICanvasDrawerMessageFragment";
};
export type AICanvasDrawerMessageFragment$key = {
  readonly " $data"?: AICanvasDrawerMessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AICanvasDrawerMessageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AICanvasDrawerMessageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AIGenerationMessageOutput",
      "kind": "LinkedField",
      "name": "output",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AIGenerationSearchResult",
          "kind": "LinkedField",
          "name": "searchResults",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "query",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urls",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AIGenerationCurriculumModule",
          "kind": "LinkedField",
          "name": "curriculumModules",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AIGenerationCurriculumContent",
              "kind": "LinkedField",
              "name": "content",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "summary",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AIGenerationMessageInput",
      "kind": "LinkedField",
      "name": "input",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prompt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchWeb",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AIGenerationMessage",
  "abstractKey": null
};
})();

(node as any).hash = "8f0f2717cf354253e7d19e5b5ad266f8";

export default node;
