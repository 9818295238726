/**
 * @generated SignedSource<<83afd26e398645394a11e471c61a1ac9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AIGenerationMessageKind = "output" | "input" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AICanvasDrawerMessageSidebar_messages$data = {
  readonly input: {
    readonly searchWeb: boolean | null;
    readonly prompt: string | null;
  };
  readonly id: string;
  readonly messages: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly kind: AIGenerationMessageKind;
        readonly output: {
          readonly curriculumModules: ReadonlyArray<{
            readonly title: string;
            readonly content: ReadonlyArray<{
              readonly title: string;
              readonly type: ContentType;
              readonly summary: string;
            }>;
          }> | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"AICanvasDrawerMessageFragment">;
      };
    }>;
    readonly pageInfo: {
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AIGenerationReferenceListFragment">;
  readonly " $fragmentType": "AICanvasDrawerMessageSidebar_messages";
};
export type AICanvasDrawerMessageSidebar_messages$key = {
  readonly " $data"?: AICanvasDrawerMessageSidebar_messages$data;
  readonly " $fragmentSpreads": FragmentRefs<"AICanvasDrawerMessageSidebar_messages">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "messages"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./AICanvasDrawerMessageSidebarRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "AICanvasDrawerMessageSidebar_messages",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AIGenerationReferenceListFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AIGenerationInput",
      "kind": "LinkedField",
      "name": "input",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchWeb",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prompt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": "messages",
      "args": null,
      "concreteType": "AIGenerationMessageNodeConnection",
      "kind": "LinkedField",
      "name": "__AICanvasDrawerMessageSidebarFragment_messages_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AIGenerationMessageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AIGenerationMessage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AIGenerationMessageOutput",
                  "kind": "LinkedField",
                  "name": "output",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AIGenerationCurriculumModule",
                      "kind": "LinkedField",
                      "name": "curriculumModules",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AIGenerationCurriculumContent",
                          "kind": "LinkedField",
                          "name": "content",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "summary",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AICanvasDrawerMessageFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AIGeneration",
  "abstractKey": null
};
})();

(node as any).hash = "11ddda13258ea53f7c81b4ae346d9733";

export default node;
