/**
 * @generated SignedSource<<557fdd9197de429025e29134d3e6f73b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AIGenerationStatus = "pending" | "running" | "draft" | "completed" | "failed" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type ResumeAIGenerationInput = {
  id: string;
  acceptedMessageId?: string | null;
  curriculumModules?: ReadonlyArray<AIGenerationCurriculumModuleInput> | null;
  prompt?: string | null;
  promptModuleIndex?: number | null;
  promptContentIndex?: number | null;
  referenceEmbeddingSourceIds?: ReadonlyArray<string> | null;
  referenceUrl?: string | null;
  uploadedAssetIds?: ReadonlyArray<string> | null;
  searchWeb?: boolean | null;
};
export type AIGenerationCurriculumModuleInput = {
  title: string;
  content: ReadonlyArray<AIGenerationCurriculumContentInput>;
};
export type AIGenerationCurriculumContentInput = {
  title: string;
  type: ContentType;
  summary: string;
};
export type AICanvasDrawerContentMutation$variables = {
  input: ResumeAIGenerationInput;
};
export type AICanvasDrawerContentMutation$data = {
  readonly response: {
    readonly node: {
      readonly status: AIGenerationStatus;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AICanvasDrawerContentMutation = {
  variables: AICanvasDrawerContentMutation$variables;
  response: AICanvasDrawerContentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AICanvasDrawerContentMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ResumeAIGenerationResponse",
        "kind": "LinkedField",
        "name": "resumeAIGeneration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AICanvasDrawerContentMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ResumeAIGenerationResponse",
        "kind": "LinkedField",
        "name": "resumeAIGeneration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AIGeneration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "297e7bdf4a1731d3a66712886131f545",
    "id": null,
    "metadata": {},
    "name": "AICanvasDrawerContentMutation",
    "operationKind": "mutation",
    "text": "mutation AICanvasDrawerContentMutation(\n  $input: ResumeAIGenerationInput!\n) {\n  response: resumeAIGeneration(input: $input) {\n    node {\n      status\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0346744e59aad598a35dd03f361d159f";

export default node;
