import { ResumeWithAIFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoSection, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import CurriculumOutlineSummary from "./CurriculumOutlineSummary"

const TYPE_LABELS: Record<string, string> = {
  text: "Lesson",
  assignment: "Assignment",
  quiz: "Quiz",
}

interface CurriculumOutlineContentProps {
  form: FormStore<ResumeWithAIFormState>
  loading?: boolean
}

const CurriculumOutlineContent = observer(
  ({ form, loading }: CurriculumOutlineContentProps) => {
    const classes = useStyles()
    const curriculumModules = form.state.curriculumModules || []

    return (
      <div className={classes.outline}>
        <CurriculumOutlineSummary
          curriculumModules={curriculumModules}
          loading={loading}
        />
        {curriculumModules.map((module, index) => (
          <DiscoSection key={module.title} className={classes.module} radius={"medium"}>
            <DiscoText variant={"heading-md"} className={classes.moduleTitle}>
              {`Module ${index + 1}: ${module.title}`}
            </DiscoText>
            <div className={classes.items}>
              {module.content.map((item) => (
                <div key={item.title} className={classes.item}>
                  <div className={classes.itemTitleRow}>
                    <div className={classes.itemContent}>
                      <div className={classes.titleChipContainer}>
                        <DiscoText component={"span"} variant={"body-md-700"}>
                          {item.title}
                        </DiscoText>
                        <DiscoChip
                          className={classes.typeChip}
                          label={TYPE_LABELS[item.type] ?? item.type}
                          color={"blue"}
                          textVariant={"body-xs-500"}
                        />
                      </div>
                      <DiscoText variant={"body-md"} className={classes.itemSummary}>
                        {item.summary}
                      </DiscoText>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DiscoSection>
        ))}
      </div>
    )
  }
)

const useStyles = makeUseStyles((theme) => ({
  outline: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1000px",
    gap: theme.spacing(1),
  },
  module: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  moduleTitle: {
    color: theme.palette.text.primary,
  },
  items: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  item: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    position: "relative",
  },
  itemTitleRow: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  itemContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    width: "100%",
  },
  titleChipContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  itemSummary: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(0.5),
  },
  typeChip: {
    flexShrink: 0,
  },
}))

export default CurriculumOutlineContent
