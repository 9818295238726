import { GenerateWithAIFormState } from "@/content/ai/button/GenerateWithAIButton"
import { ResumeWithAIFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import AIInput from "@components/ai/AIInput"
import AIText from "@components/ai/AIText"
import { AI_GENERATION_REFERENCE_TYPES } from "@components/dropzone/FileDropzone"
import Form from "@components/form/Form"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import {
  DiscoButton,
  DiscoDropdown,
  DiscoIcon,
  DiscoIconButton,
  DiscoLinkInput,
  DiscoSection,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useMemo, useRef, useState } from "react"
import AIFormAttachmentTags from "./AIFormAttachmentTags"
import AIReferencesListEmbeddingSourceSelect from "./AIReferencesListEmbeddingSourceSelect"
import { EXAMPLE_PROMPTS } from "./utils/CurriculumGenerationUtils"

type GenerateWithAIFormProps = TestIDProps & {
  form: FormStore<GenerateWithAIFormState | ResumeWithAIFormState>
  onSubmit: () => void
  minimized?: boolean
}

function GenerateWithAIForm(props: GenerateWithAIFormProps) {
  const {
    form,
    onSubmit,
    testid = "GenerateContentWithAIForm",
    minimized = false,
  } = props

  const hasEntitlement = useHasEntitlement("ai_content_generation")
  const selectedItemsRef = useRef<HTMLDivElement>(null)
  const [tagsHeight, setTagsHeight] = useState(0)
  const isMobile = useIsMobile()

  // Update height when items change or when the div resizes
  useEffect(() => {
    if (!selectedItemsRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setTagsHeight(entry.contentRect.height)
      }
    })

    resizeObserver.observe(selectedItemsRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, []) // Empty dependency array since we're using ResizeObserver

  const theme = useTheme()
  const [isLinkDropdownOpen, setIsLinkDropdownOpen] = useState(false)
  const [showAssetSelector, setShowAssetSelector] = useState(false)
  const [showContentSelector, setShowContentSelector] = useState(false)

  const randomPlaceholder = useMemo(() => {
    if (minimized) {
      return "Add a follow up prompt to edit your curriculum..."
    }
    const randomIndex = Math.floor(Math.random() * EXAMPLE_PROMPTS.length)
    return EXAMPLE_PROMPTS[randomIndex]
  }, [minimized])

  const totalCount =
    form.state.assetEmbeddingSourceIds.length +
    form.state.contentEmbeddingSourceIds.length +
    (form.state.uploadedAssets?.length || 0)

  const mobileCount = totalCount + (form.state.referenceUrl ? 1 : 0)

  const classes = useStyles({ totalCount, tagsHeight })

  return (
    <DiscoSection className={classes.container}>
      <Form testid={`${testid}.form`} errorInfo={null}>
        <div className={classes.inputWrapper}>
          <AIInput
            testid={`${testid}.prompt-input`}
            value={form.state.prompt}
            onChange={handlePromptChange}
            disabled={!hasEntitlement}
            placeholder={randomPlaceholder}
            showBadge={false}
            minHeight={minimized ? `${100 + tagsHeight}px` : `${180 + tagsHeight}px`}
            className={classes.input}
            onEnterPress={minimized ? handleSubmit : undefined}
          />
          <div ref={selectedItemsRef} className={classes.selectedItemsWrapper}>
            <AIFormAttachmentTags
              testid={testid}
              assetEmbeddingSourceIds={form.state.assetEmbeddingSourceIds}
              contentEmbeddingSourceIds={form.state.contentEmbeddingSourceIds}
              uploadedAssets={form.state.uploadedAssets || []}
              referenceUrl={form.state.referenceUrl}
              onRemoveAssetEmbeddingSource={handleRemoveAssetEmbeddingSource}
              onRemoveContentEmbeddingSource={handleRemoveContentEmbeddingSource}
              onRemoveUploadedAsset={handleRemoveUploadedAsset}
              onRemoveReferenceUrl={handleRemoveReferenceUrl}
            />
          </div>

          {showAssetSelector && (
            <div className={classes.selectorWrapper}>
              <AIReferencesListEmbeddingSourceSelect
                entity={"asset"}
                values={form.state.assetEmbeddingSourceIds}
                onSelect={handleAssetEmbeddingSourceIdsSelect}
                testid={`${testid}.media-select`}
                isOpen={showAssetSelector}
                onClose={() => setShowAssetSelector(false)}
                onOpen={() => setShowAssetSelector(true)}
              />
            </div>
          )}

          {showContentSelector && (
            <div className={classes.selectorWrapper}>
              <AIReferencesListEmbeddingSourceSelect
                entity={"content"}
                values={form.state.contentEmbeddingSourceIds}
                onSelect={handleContentEmbeddingSourceIdsSelect}
                testid={`${testid}.content-select`}
                isOpen={showContentSelector}
                onClose={() => setShowContentSelector(false)}
                onOpen={() => setShowContentSelector(true)}
              />
            </div>
          )}

          <div className={classes.controls}>
            <div className={classes.leftControls}>
              <DiscoDropdown
                menuClasses={{ paper: classes.dropdown }}
                menuButton={({ onClick }) => (
                  <DiscoTooltip
                    content={
                      "Add attachments to enhance AI generation with relevant context"
                    }
                  >
                    <DiscoIconButton
                      onClick={onClick}
                      size={"small"}
                      testid={`${testid}.attach-button`}
                      className={`${classes.controlButton} ${classes.webSearchButton}`}
                      color={mobileCount > 0 ? theme.palette.aiColors.blue : undefined}
                    >
                      <DiscoIcon
                        icon={"add-circle"}
                        className={
                          mobileCount > 0 ? theme.palette.aiColors.blue : undefined
                        }
                      />
                      {mobileCount > 0 && (
                        <AIText variant={"body-sm-700"} className={classes.webSearchText}>
                          {getDisplayText()}
                        </AIText>
                      )}
                    </DiscoIconButton>
                  </DiscoTooltip>
                )}
              >
                <DiscoDropdownItem
                  icon={<DiscoIcon icon={"play"} />}
                  title={"Select Media"}
                  testid={`${testid}.attach-media`}
                  onClick={toggleAssetSelector}
                />

                <DiscoDropdownItem
                  icon={<DiscoIcon icon={"file-dock"} />}
                  title={"Select Content"}
                  testid={`${testid}.attach-content`}
                  onClick={toggleContentSelector}
                />
                <MediaLibraryButton
                  allowedMimeTypes={["video/mp4", "application/pdf"]}
                  allowedFileTypes={["video", "document"]}
                  onUpload={handleAssetUpload}
                  onMediaSelect={handleAssetUpload}
                  dropzoneOptions={{ accept: AI_GENERATION_REFERENCE_TYPES }}
                  disabled={form.state.uploadedAssets.length >= 5}
                  PopoverProps={{
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                  }}
                >
                  {(btnprops) => (
                    <DiscoDropdownItem
                      {...btnprops}
                      icon={<DiscoIcon icon={"upload"} />}
                      title={`Upload File${
                        form.state.uploadedAssets.length > 0
                          ? ` (${form.state.uploadedAssets.length}/5)`
                          : ""
                      }`}
                      testid={`${testid}.upload-file`}
                      disabled={form.state.uploadedAssets.length >= 5}
                    />
                  )}
                </MediaLibraryButton>
                <DiscoDropdown
                  open={isLinkDropdownOpen}
                  onClose={() => setIsLinkDropdownOpen(false)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  menuClasses={{ paper: classes.linkInputDropdown }}
                  menuButton={() => (
                    <DiscoDropdownItem
                      icon={<DiscoIcon icon={"link"} />}
                      title={form.state.referenceUrl ? "Update Link" : "Add Link"}
                      onClick={handleAddLink}
                      testid={`${testid}.add-link`}
                    />
                  )}
                >
                  <div className={classes.linkInput}>
                    <DiscoText variant={"body-md-500"} className={classes.linkInputTitle}>
                      {"Reference Link"}
                    </DiscoText>
                    <div className={classes.linkInputWrapper}>
                      <DiscoLinkInput
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        data-testid={`${testid}.link-input`}
                        value={form.state.referenceUrl}
                        onChange={handleLinkChange}
                        onKeyPress={handleLinkKeyPress}
                        disabled={!hasEntitlement}
                        fullWidth
                      />
                      <DiscoButton
                        onClick={() => setIsLinkDropdownOpen(false)}
                        testid={`${testid}.link-input-submit`}
                        disabled={!form.state.referenceUrl}
                      >
                        {"Submit"}
                      </DiscoButton>
                    </div>
                  </div>
                </DiscoDropdown>
              </DiscoDropdown>

              <DiscoTooltip
                content={`Enable web search to include relevant online resources in the AI response (${
                  form.state.searchWeb ? "Enabled" : "Disabled"
                })`}
              >
                <DiscoIconButton
                  onClick={() => {
                    form.state.searchWeb = !form.state.searchWeb
                  }}
                  size={"small"}
                  testid={`${testid}.web-search-button`}
                  className={`${classes.controlButton} ${classes.webSearchButton}`}
                  color={form.state.searchWeb ? theme.palette.aiColors.blue : undefined}
                >
                  <DiscoIcon icon={"globe"} />
                  {form.state.searchWeb && !isMobile && !minimized && (
                    <AIText variant={"body-sm-700"} className={classes.webSearchText}>
                      {"Web Search"}
                    </AIText>
                  )}
                </DiscoIconButton>
              </DiscoTooltip>
            </div>

            <AIButton
              disabled={!form.state.prompt?.trim()}
              onClick={onSubmit}
              shouldDisplaySpinner={form.isSubmitting}
              testid={`${testid}.generate-button`}
              noIcon={minimized}
            >
              {minimized ? (
                <DiscoIcon color={"white"} active icon={"iconsax.arrow-up"} />
              ) : (
                "Generate"
              )}
            </AIButton>
          </div>
        </div>
      </Form>
    </DiscoSection>
  )

  function getDisplayText() {
    if (isMobile) {
      return mobileCount || ""
    }

    const parts = []
    if (totalCount > 0) {
      parts.push(`${totalCount} ${pluralize("Attachment", totalCount)}`)
    }
    if (form.state.referenceUrl) {
      parts.push("1 Link")
    }
    return parts.join(" + ")
  }

  function handleAssetUpload(result: MediaResult) {
    if (form.state.uploadedAssets.length >= 5) return
    form.state.uploadedAssetIds!.push(result.id)
    form.state.uploadedAssets.push(result)
  }

  function handlePromptChange(e: React.ChangeEvent<HTMLInputElement>) {
    form.state.prompt = e.target.value || ""
  }

  function handleSubmit() {
    if (form.state.prompt.trim()) {
      onSubmit()
    }
  }

  function handleAddLink() {
    setIsLinkDropdownOpen(true)
  }

  function handleLinkChange(value: string) {
    if (value) {
      form.state.referenceUrl = value
    }
  }

  function handleLinkKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      setIsLinkDropdownOpen(false)
    }
  }

  function handleAssetEmbeddingSourceIdsSelect(ids: GlobalID[]) {
    form.state.assetEmbeddingSourceIds.replace(ids)
    setShowAssetSelector(false)
  }

  function handleContentEmbeddingSourceIdsSelect(ids: GlobalID[]) {
    form.state.contentEmbeddingSourceIds.replace(ids)
    setShowContentSelector(false)
  }

  function toggleAssetSelector() {
    setShowContentSelector(false)
    setShowAssetSelector(!showAssetSelector)
  }

  function toggleContentSelector() {
    setShowAssetSelector(false)
    setShowContentSelector(!showContentSelector)
  }

  function handleRemoveAssetEmbeddingSource(idToRemove: GlobalID) {
    form.state.assetEmbeddingSourceIds.replace(
      form.state.assetEmbeddingSourceIds.filter((id) => id !== idToRemove)
    )
  }

  function handleRemoveContentEmbeddingSource(idToRemove: GlobalID) {
    form.state.contentEmbeddingSourceIds.replace(
      form.state.contentEmbeddingSourceIds.filter((id) => id !== idToRemove)
    )
  }

  function handleRemoveUploadedAsset(mediaToRemove: MediaResult) {
    form.state.uploadedAssetIds!.replace(
      form.state.uploadedAssetIds!.filter((id) => id !== mediaToRemove.id)
    )
    form.state.uploadedAssets!.replace(
      form.state.uploadedAssets!.filter((asset) => asset.id !== mediaToRemove.id)
    )
  }

  function handleRemoveReferenceUrl() {
    form.state.referenceUrl = ""
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.large,
    width: "100%",
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    "& textarea": {
      paddingBottom: ({ tagsHeight }: { tagsHeight: number; totalCount: number }) => {
        const basePadding = 6
        const tagsSpace = tagsHeight ? Math.ceil(tagsHeight / 8) : 0
        const buffer = 2
        return theme.spacing(basePadding + tagsSpace + buffer)
      },
    },
  },
  controls: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    right: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  leftControls: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  controlButton: {
    background: "transparent",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  dropdown: {
    minWidth: "150px",
  },
  webSearchButton: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  webSearchText: {
    marginLeft: theme.spacing(0.5),
  },
  linkInput: {
    width: "800px",
    maxWidth: "70vw",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
  linkInputDropdown: {
    maxWidth: "none !important",
    boxShadow: theme.palette.groovyDepths.boxShadow,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 1),
    },
  },
  linkInputTitle: {
    marginBottom: theme.spacing(1),
  },
  selectorWrapper: {
    position: "absolute",
    bottom: theme.spacing(7),
    left: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1),
  },
  selectedItemsWrapper: {
    position: "absolute",
    bottom: theme.spacing(7), // Fixed position above controls
    left: theme.spacing(1),
    right: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
    zIndex: 1,
  },
  linkInputWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}))

export default observer(GenerateWithAIForm)
