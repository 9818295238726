/**
 * @generated SignedSource<<031e7f3efd6bdf050a55bc1af9a14118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AIGenerationReferenceStatus = "pending" | "completed" | "failed" | "%future added value";
export type AIGenerationStatus = "pending" | "running" | "draft" | "completed" | "failed" | "%future added value";
export type useTrackAIGenerationStatusQuery$variables = {
  curriculumId: string;
  skip: boolean;
};
export type useTrackAIGenerationStatusQuery$data = {
  readonly curriculum?: {
    readonly aiGeneration?: {
      readonly id: string;
      readonly status: AIGenerationStatus;
      readonly acceptedMessageId: string | null;
      readonly input: {
        readonly searchWeb: boolean | null;
      };
      readonly messages: {
        readonly totalCount: number;
      };
      readonly references: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly status: AIGenerationReferenceStatus;
            readonly url: string | null;
            readonly summary: string | null;
            readonly embeddingSource: {
              readonly asset: {
                readonly name: string;
              } | null;
              readonly content: {
                readonly name: string | null;
              } | null;
            } | null;
            readonly uploadedAsset: {
              readonly name: string;
            } | null;
          };
        }>;
      };
    } | null;
  } | null;
};
export type useTrackAIGenerationStatusQuery = {
  variables: useTrackAIGenerationStatusQuery$variables;
  response: useTrackAIGenerationStatusQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "curriculumId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "curriculumId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptedMessageId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AIGenerationInput",
  "kind": "LinkedField",
  "name": "input",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchWeb",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AIGenerationMessageNodeConnection",
  "kind": "LinkedField",
  "name": "messages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v9/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "curriculum",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGeneration",
                    "kind": "LinkedField",
                    "name": "aiGeneration",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AIGenerationReferenceNodeConnection",
                        "kind": "LinkedField",
                        "name": "references",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AIGenerationReferenceNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AIGenerationReference",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmbeddingSource",
                                    "kind": "LinkedField",
                                    "name": "embeddingSource",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Asset",
                                        "kind": "LinkedField",
                                        "name": "asset",
                                        "plural": false,
                                        "selections": (v10/*: any*/),
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Content",
                                        "kind": "LinkedField",
                                        "name": "content",
                                        "plural": false,
                                        "selections": (v10/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "uploadedAsset",
                                    "plural": false,
                                    "selections": (v10/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Curriculum",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTrackAIGenerationStatusQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "curriculum",
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AIGeneration",
                    "kind": "LinkedField",
                    "name": "aiGeneration",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AIGenerationReferenceNodeConnection",
                        "kind": "LinkedField",
                        "name": "references",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AIGenerationReferenceNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AIGenerationReference",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EmbeddingSource",
                                    "kind": "LinkedField",
                                    "name": "embeddingSource",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Asset",
                                        "kind": "LinkedField",
                                        "name": "asset",
                                        "plural": false,
                                        "selections": (v11/*: any*/),
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Content",
                                        "kind": "LinkedField",
                                        "name": "content",
                                        "plural": false,
                                        "selections": (v11/*: any*/),
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "uploadedAsset",
                                    "plural": false,
                                    "selections": (v11/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Curriculum",
                "abstractKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "fa2afc1898f3cebcbfdc2a48fb5ef5ba",
    "id": null,
    "metadata": {},
    "name": "useTrackAIGenerationStatusQuery",
    "operationKind": "query",
    "text": "query useTrackAIGenerationStatusQuery(\n  $curriculumId: ID!\n  $skip: Boolean!\n) {\n  curriculum: node(id: $curriculumId) @skip(if: $skip) {\n    __typename\n    ... on Curriculum {\n      aiGeneration {\n        id\n        status\n        acceptedMessageId\n        input {\n          searchWeb\n        }\n        messages {\n          totalCount\n        }\n        references {\n          edges {\n            node {\n              id\n              status\n              url\n              summary\n              embeddingSource {\n                asset {\n                  name\n                  id\n                }\n                content {\n                  name\n                  id\n                }\n                id\n              }\n              uploadedAsset {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5061888b18cabce7ee5fa27ea73e729";

export default node;
