import { GenerateWithAIFormState } from "@/content/ai/button/GenerateWithAIButton"
import ResumeWithAIButton from "@/content/ai/button/ResumeWithAIButton"
import AICanvasDrawerHeader from "@/content/ai/drawer/AICanvasDrawerHeader"
import AICanvasDrawerMessageSidebar from "@/content/ai/drawer/AICanvasDrawerMessageSidebar"
import CurriculumOutlineContent from "@/content/ai/drawer/CurriculumOutlineContent"
import {
  AICanvasDrawerContentMutation,
  AIGenerationCurriculumModuleInput,
} from "@/content/ai/drawer/__generated__/AICanvasDrawerContentMutation.graphql"
import { AICanvasDrawerContentPaginationQuery } from "@/content/ai/drawer/__generated__/AICanvasDrawerContentPaginationQuery.graphql"
import { AICanvasDrawerContentQuery } from "@/content/ai/drawer/__generated__/AICanvasDrawerContentQuery.graphql"
import { AICanvasDrawerContent_PaginationFragment$key } from "@/content/ai/drawer/__generated__/AICanvasDrawerContent_PaginationFragment.graphql"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { ObservableState, useFormStore } from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import DiscoLoaderDots from "@disco-ui/loader/DiscoLoaderDots"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

export type ResumeWithAIFormState = GenerateWithAIFormState & {
  curriculumModules?: AIGenerationCurriculumModuleInput[]
  acceptedMessageId?: string
}

const AICanvasDrawerContent = observer(() => {
  const drawer = useGlobalDrawer("aiCanvas")
  const classes = useStyles()
  const activeProduct = useActiveProduct()!

  const { aiGenerationId, entityId } = drawer.params

  const { node } = useLazyLoadQuery<AICanvasDrawerContentQuery>(
    graphql`
      query AICanvasDrawerContentQuery($id: ID!, $first: Int!, $after: String) {
        node(id: $id) {
          __typename
          ... on AIGeneration {
            id
            generatedAt
            kind
            status
            references {
              edges {
                node {
                  id
                  status
                  summary
                  url
                }
              }
            }
            ...AICanvasDrawerContent_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      id: aiGenerationId,
      first: 100,
      after: null,
    },
    { fetchPolicy: "network-only" }
  )

  const aiGeneration = Relay.narrowNodeType(node, "AIGeneration")

  const { data, loadNext } = usePaginationFragment<
    AICanvasDrawerContentPaginationQuery,
    AICanvasDrawerContent_PaginationFragment$key
  >(
    graphql`
      fragment AICanvasDrawerContent_PaginationFragment on AIGeneration
      @refetchable(queryName: "AICanvasDrawerContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        id
        ...AICanvasDrawerMessageSidebar_messages @arguments(first: $first, after: $after)
        messages(first: $first, after: $after)
          @connection(key: "AICanvasDrawerContent_messages") {
          edges {
            node {
              id
              kind
              output {
                curriculumModules {
                  title
                  content {
                    title
                    type
                    summary
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    aiGeneration
  )

  const { status, searchWeb } = useTrackAIGenerationStatus({
    entityId,
    skip: false,
    onStatusChange: (newsSatus) => {
      if (newsSatus === "draft") {
        commitLocalUpdate(RelayEnvironment, (store) => {
          if (!aiGeneration) return
          const aiGenerationRecord = store.get(aiGeneration.id)
          if (!aiGenerationRecord) return

          const messagesConnections = ConnectionHandlerPlus.getConnections(
            aiGenerationRecord,
            "AICanvasDrawerMessageSidebarFragment_messages"
          )
          if (!messagesConnections.length) return
          const connection = messagesConnections[0]
          const edges = connection.getLinkedRecords("edges") || []
          const filteredEdges = edges.filter((edge) => {
            const messageNode = edge?.getLinkedRecord("node")
            const id = messageNode?.getValue("id")
            return typeof id !== "string" || !id.startsWith("tmp_input_")
          })
          connection.setLinkedRecords(filteredEdges, "edges")
        })

        loadNext(2)
      }
    },
  })

  const form = useFormStore<AICanvasDrawerContentMutation, ResumeWithAIFormState>(
    graphql`
      mutation AICanvasDrawerContentMutation($input: ResumeAIGenerationInput!) {
        response: resumeAIGeneration(input: $input) {
          node {
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      prompt: "",
      referenceUrl: "",
      referenceEmbeddingSourceIds: [],
      entityId: "",

      productId: null,
      assetEmbeddingSourceIds: [],
      contentEmbeddingSourceIds: [],
      trainingDataEmbeddingSourceIds: [],
      uploadedAssetIds: [],
      uploadedAssets: [],
      referenceMeter: 0,
      searchWeb,
      curriculumModules: [],
      acceptedMessageId: undefined,
    }
  )

  const regenerationLoading = status === "running" || status === "pending"
  const messageList = Relay.connectionToArray(data?.messages)

  useEffect(() => {
    const mostRecentOutput = messageList.filter((msg) => msg.kind === "output").pop()
    if (mostRecentOutput?.id) {
      form.state.acceptedMessageId = mostRecentOutput.id
      form.state.curriculumModules?.replace(
        mostRecentOutput.output
          ?.curriculumModules as unknown as ObservableState<AIGenerationCurriculumModuleInput>[]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList.length, status])

  if (!aiGeneration || !data) return null

  const CurriculumName = `${activeProduct?.name} Curriculum`

  const headerActions = (
    <>
      <ResumeWithAIButton
        aiGenerationId={aiGenerationId}
        onSuccess={drawer.close}
        testid={"curriculum-outline-review.approve-button"}
        form={form}
      >
        {"Generate Curriculum"}
      </ResumeWithAIButton>
    </>
  )

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <AICanvasDrawerMessageSidebar
          messageKey={data}
          form={form}
          isLoadingGeneration={regenerationLoading}
        />
      </div>
      <div className={classes.mainContent}>
        <AICanvasDrawerHeader
          title={CurriculumName}
          onClose={drawer.close}
          actions={headerActions}
        />
        <div className={classes.mainContentWrapper}>
          <ScrollShadowContainer>
            <div className={classes.contentContainer}>
              <CurriculumOutlineContent form={form} loading={regenerationLoading} />
            </div>
          </ScrollShadowContainer>
          {regenerationLoading && (
            <div className={classes.loadingOverlay}>
              <DiscoLoaderDots />
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    overflow: "hidden",
  },
  sidebar: {
    width: "30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    overflow: "hidden",
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
  mainContentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    position: "relative",
  },
  contentContainer: {
    width: "100%",
    maxWidth: "750px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3),
    width: theme.spacing(20),
    height: theme.spacing(20),
    backgroundColor: `${theme.palette.background.paper}EE`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
}))

export default AICanvasDrawerContent
