import GenerateWithAIForm from "@/content/ai/GenerateWithAIForm"
import { ResumeWithAIFormState } from "@/content/ai/drawer/AICanvasDrawerContent"
import FormStore from "@/core/form/store/FormStore"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { RecordSourceProxy, commitLocalUpdate } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

type AICanvasDrawerMessageFormProps = TestIDProps & {
  onSubmit: (prompt: string) => void
  aiGenerationId: GlobalID
  form: FormStore<ResumeWithAIFormState>
}

function AICanvasDrawerMessageForm({
  onSubmit,
  aiGenerationId,
  form,
  testid = "AICanvasDrawerMessageForm",
}: AICanvasDrawerMessageFormProps) {
  const handleSubmit = async () => {
    if (!form.state.curriculumModules?.length) {
      console.error("No curriculum modules available")
      return
    }

    // Create a temporary message ID with specific prefix
    const tmpInputMessageId = `tmp_input_${uuidv4()}`

    try {
      // Add temporary input message to the UI
      commitLocalUpdate(RelayEnvironment, (store) => {
        const messagesConnection = getMessagesConnection(store)
        if (!messagesConnection) return

        // Create and insert the input message
        const tmpInputMessage = Relay.fabricateNode(store, "AIGenerationMessage", {
          id: tmpInputMessageId,
          kind: "input",
          input: {
            prompt: form.state.prompt,
            searchWeb: form.state.searchWeb,
            ...(form.state.referenceUrl && { referenceUrl: form.state.referenceUrl }),
            referenceEmbeddingSourceIds: [
              ...form.state.contentEmbeddingSourceIds,
              ...form.state.assetEmbeddingSourceIds,
              ...form.state.trainingDataEmbeddingSourceIds,
            ],
          },
        })
        Relay.insertNodeIntoPaginatedConnection(
          store,
          messagesConnection,
          tmpInputMessage
        )
      })

      const { didSave } = await form.submit({
        id: aiGenerationId,
        prompt: form.state.prompt,
        curriculumModules: form.state.curriculumModules,
        searchWeb: form.state.searchWeb,
        ...(form.state.referenceUrl && { referenceUrl: form.state.referenceUrl }),
        uploadedAssetIds: form.state.uploadedAssetIds,
        referenceEmbeddingSourceIds: [
          ...form.state.contentEmbeddingSourceIds,
          ...form.state.assetEmbeddingSourceIds,
          ...form.state.trainingDataEmbeddingSourceIds,
        ],
      })

      if (didSave) {
        onSubmit(form.state.prompt)
        // Reset form state
        form.state.prompt = ""
        form.state.referenceUrl = ""
        form.state.uploadedAssetIds?.clear()
        form.state.contentEmbeddingSourceIds?.clear()
        form.state.assetEmbeddingSourceIds?.clear()
        form.state.trainingDataEmbeddingSourceIds?.clear()
      }
    } catch (error) {
      console.error("Error during form submission:", error)
      throw error
    }
  }

  return (
    <GenerateWithAIForm form={form} onSubmit={handleSubmit} testid={testid} minimized />
  )

  function getMessagesConnection(store: RecordSourceProxy) {
    const aiGenerationRecord = store.get(aiGenerationId)
    if (!aiGenerationRecord) return

    const messagesConnections = ConnectionHandlerPlus.getConnections(
      aiGenerationRecord,
      "AICanvasDrawerMessageSidebarFragment_messages"
    )
    if (!messagesConnections.length) return
    return messagesConnections[0]
  }
}

export default observer(AICanvasDrawerMessageForm)
